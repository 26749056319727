////////////////////////////////////////////////////////////////////////////////
// Core System Color Variables
////////////////////////////////////////////////////////////////////////////////

// Greys / Tints
@asphalt: #1F2532;
@cadet:  #465561;
@slate: #7F94A2;
@geyser: #D8DDE6;
@steam: #F5F7F9;
@white: #FFFFFF;


// Primary / Info Blues
@night: #063D7A;
@evening: #1E71CD;
@noon: #268AF9;
@morning: #E9F3FE;

// Positive / Success Greens
@pine: #10572F;
@ivy: #208052;
@grass: #26CC6D;
@mint: #E9F9F0;

// Warning / Caution Yellows
@mocha: #6F4704;
@hazelnut: #966C24;
@butterscotch: #FFB63D;
@vanilla: #FFF4E2;

// Danger / Error Reds
@cranberry: #990112;
@cherry: #C83426;
@strawberry: #F55D50;
@watermelon: #FEE7E5;





////////////////////////////////////////////////////////////////////////////////
// Core Elevation Box-Shadow Levels  - Variables and Mixins
////////////////////////////////////////////////////////////////////////////////

// No Elevation
@level-0: none;

.level-0 {
  box-shadow: @level-0;
}

// Low Elevation Level 1
@level-1: 0px 2px 4px 0px fade(@asphalt, 20%);

.level-1 {
  box-shadow: @level-1;
}

// Mid Elevation Level 2
@level-2: 0px 6px 12px 0px fade(@asphalt, 20%);

.level-2 {
  box-shadow: @level-2;
}

// Upper Elevation Level 3
@level-3: 0px 10px 16px 0px fade(@asphalt, 15%);

.level-3 {
  box-shadow: @level-3;
}

// Top Elevation Level 4
@level-4: 0px 16px 30px 0px fade(@asphalt, 50%);

.level-4 {
  box-shadow: @level-4;
}




////////////////////////////////////////////////////////////////////////////////
// Misc System-wide Core Variables
//
// Prefix all variables below with @core- to make them easy to identify when
// used in component files.
////////////////////////////////////////////////////////////////////////////////

//Core Font Stack
@font-face {
  font-family: "Open Sans";
  src: url('../../assets/fonts/OpenSans-Regular.ttf');
}
@core-font-family: "Open Sans","Helvetica","Arial",sans-serif;
@core-font-size: 16px;
@core-line-height: 1.5;

// Base Animation Timing
// Intentionally left out out specific property to transition on. This enables
// the freedom to use this variable in conjunction with declaring a specific
// property. It will also default to 'all' if no specific property is declared.
@core-animation-base: .2s ease-in-out;


//Base Border Radius
@core-border-radius: 5px;

//Ellipsis Mixin
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
