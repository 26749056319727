.ui-helper-hidden {
  display: none;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}
.ui-helper-clearfix:after {
  clear: both;
}
.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  /* support: IE8 */
}
.ui-front {
  z-index: 100;
}
/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
  pointer-events: none;
}
/* Icons
----------------------------------*/
.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}
.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block;
}
/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ui-datepicker {
  width: 20em;
  padding: 1.5em 1em;
  display: none;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 0.2em 0;
  margin-bottom: 10px;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
}
.ui-datepicker .ui-datepicker-prev:focus,
.ui-datepicker .ui-datepicker-next:focus {
  border: 1px solid #268AF9;
  border-radius: 50%;
}
.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}
.ui-datepicker .ui-datepicker-next {
  right: 2px;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 40%;
  margin-top: -8px;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
  font-size: 16px;
}
.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 45%;
}
.ui-datepicker-year::before {
  content: "- ";
}
.ui-datepicker table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}
.ui-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  border: 0;
  color: #465561;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
.ui-datepicker td {
  border: 0;
  padding: 1px;
}
.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: 0.2em;
  text-align: center;
  text-decoration: none;
}
/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
  padding: 0 0.7em;
}
.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto 0.4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui-datepicker-multi .ui-datepicker-buttonpane .ui-state-default {
  width: auto;
}
.ui-datepicker-multi .ui-datepicker-buttonpane .ui-datepicker-close {
  color: #268AF9;
}
.ui-datepicker-multi .ui-datepicker-buttonpane .ui-button:focus {
  border-radius: 0px !important;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}
/* RTL support */
.ui-datepicker-rtl {
  direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}
.ui-datepicker-buttonpane {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui-datepicker-buttonpane button {
  width: auto;
}
.ui-datepicker-buttonpane .ui-button:focus {
  border-radius: 0px !important;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}
/* Icons */
.ui-datepicker .ui-icon {
  display: block;
  overflow: hidden;
  background-repeat: no-repeat;
  left: 0.5em;
  top: 0.3em;
}
/* Component containers
----------------------------------*/
.ui-widget {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}
.ui-widget .ui-widget {
  font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}
.ui-widget.ui-widget-content {
  box-shadow: 0px 2px 4px 0px rgba(31, 37, 50, 0.2);
  border-radius: 5px;
}
.ui-widget-content {
  background: #ffffff;
  color: #333333;
}
.ui-widget-content a {
  color: #333333;
}
.ui-widget-header {
  color: #1F2532;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}
.ui-widget-header a {
  color: #268AF9;
}
/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  color: #465561;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 19px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button,
.ui-button {
  color: #454545;
  text-decoration: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  border: 1px solid #D8DDE6;
  background-color: #D8DDE6;
  border-radius: 50%;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
a.ui-button:hover,
a.ui-button:focus {
  color: #2b2b2b;
  text-decoration: none;
}
.ui-state-hover buttonpane {
  border: 0;
  border-radius: 0;
}
.ui-visual-focus {
  box-shadow: 0 0 3px 1px #5e9ed6;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: 1px solid #268AF9;
  color: white;
  background-color: #268AF9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px 0px rgba(31, 37, 50, 0.2);
}
.ui-icon-background,
.ui-state-active .ui-icon-background {
  border: #268AF9;
  background-color: #ffffff;
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #ffffff;
  text-decoration: none;
}
/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #268AF9;
}
.ui-state-checked {
  border: 1px solid #dad55e;
  background: #fffa90;
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: #777620;
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #f1a899;
  background: #fddfdf;
  color: #5f3f3f;
}
.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: #5f3f3f;
}
.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #5f3f3f;
}
.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold;
}
.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: 0.7;
  filter: alpha(opacity=70);
  /* support: IE8 */
  font-weight: normal;
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: 0.35;
  filter: alpha(opacity=35);
  /* support: IE8 */
  background-image: none;
}
.ui-state-disabled .ui-icon {
  filter: alpha(opacity=35);
  /* support: IE8 - See #6059 */
}
/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  width: auto;
  height: auto;
}
.ui-icon,
.ui-widget-content .ui-icon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  content: "\e5cb";
  color: black;
}
.ui-widget-header .ui-icon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  content: "\e5cb";
  color: black;
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
  font-family: "Material Icons";
  content: "\e5cb";
  color: black;
}
/* positioning */
.ui-icon-blank {
  background-position: 16px 16px;
}
.ui-icon-caret-1-n {
  background-position: 0 0;
}
.ui-icon-caret-1-ne {
  background-position: -16px 0;
}
.ui-icon-caret-1-e {
  background-position: -32px 0;
}
.ui-icon-caret-1-se {
  background-position: -48px 0;
}
.ui-icon-caret-1-s {
  background-position: -65px 0;
}
.ui-icon-caret-1-sw {
  background-position: -80px 0;
}
.ui-icon-caret-1-w {
  background-position: -96px 0;
}
.ui-icon-caret-1-nw {
  background-position: -112px 0;
}
.ui-icon-caret-2-n-s {
  background-position: -128px 0;
}
.ui-icon-caret-2-e-w {
  background-position: -144px 0;
}
.ui-icon-triangle-1-n {
  background-position: 0 -16px;
}
.ui-icon-triangle-1-ne {
  background-position: -16px -16px;
}
.ui-icon-triangle-1-e {
  background-position: -32px -16px;
}
.ui-icon-triangle-1-se {
  background-position: -48px -16px;
}
.ui-icon-triangle-1-s {
  background-position: -65px -16px;
}
.ui-icon-triangle-1-sw {
  background-position: -80px -16px;
}
.ui-icon-triangle-1-w {
  background-position: -96px -16px;
}
.ui-icon-triangle-1-nw {
  background-position: -112px -16px;
}
.ui-icon-triangle-2-n-s {
  background-position: -128px -16px;
}
.ui-icon-triangle-2-e-w {
  background-position: -144px -16px;
}
.ui-icon-arrow-1-n {
  background-position: 0 -32px;
}
.ui-icon-arrow-1-ne {
  background-position: -16px -32px;
}
.ui-icon-arrow-1-e {
  background-position: -32px -32px;
}
.ui-icon-arrow-1-se {
  background-position: -48px -32px;
}
.ui-icon-arrow-1-s {
  background-position: -65px -32px;
}
.ui-icon-arrow-1-sw {
  background-position: -80px -32px;
}
.ui-icon-arrow-1-w {
  background-position: -96px -32px;
}
.ui-icon-arrow-1-nw {
  background-position: -112px -32px;
}
.ui-icon-arrow-2-n-s {
  background-position: -128px -32px;
}
.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px;
}
.ui-icon-arrow-2-e-w {
  background-position: -160px -32px;
}
.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px;
}
.ui-icon-arrowstop-1-n {
  background-position: -192px -32px;
}
.ui-icon-arrowstop-1-e {
  background-position: -208px -32px;
}
.ui-icon-arrowstop-1-s {
  background-position: -224px -32px;
}
.ui-icon-arrowstop-1-w {
  background-position: -240px -32px;
}
.ui-icon-arrowthick-1-n {
  background-position: 1px -48px;
}
.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px;
}
.ui-icon-arrowthick-1-e {
  background-position: -32px -48px;
}
.ui-icon-arrowthick-1-se {
  background-position: -48px -48px;
}
.ui-icon-arrowthick-1-s {
  background-position: -64px -48px;
}
.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px;
}
.ui-icon-arrowthick-1-w {
  background-position: -96px -48px;
}
.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px;
}
.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px;
}
.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px;
}
.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px;
}
.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px;
}
.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px;
}
.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px;
}
.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px;
}
.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px;
}
.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px;
}
.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px;
}
.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px;
}
.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px;
}
.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px;
}
.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px;
}
.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px;
}
.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px;
}
.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px;
}
.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px;
}
.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px;
}
.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px;
}
.ui-icon-arrow-4 {
  background-position: 0 -80px;
}
.ui-icon-arrow-4-diag {
  background-position: -16px -80px;
}
.ui-icon-extlink {
  background-position: -32px -80px;
}
.ui-icon-newwin {
  background-position: -48px -80px;
}
.ui-icon-refresh {
  background-position: -64px -80px;
}
.ui-icon-shuffle {
  background-position: -80px -80px;
}
.ui-icon-transfer-e-w {
  background-position: -96px -80px;
}
.ui-icon-transferthick-e-w {
  background-position: -112px -80px;
}
.ui-icon-folder-collapsed {
  background-position: 0 -96px;
}
.ui-icon-folder-open {
  background-position: -16px -96px;
}
.ui-icon-document {
  background-position: -32px -96px;
}
.ui-icon-document-b {
  background-position: -48px -96px;
}
.ui-icon-note {
  background-position: -64px -96px;
}
.ui-icon-mail-closed {
  background-position: -80px -96px;
}
.ui-icon-mail-open {
  background-position: -96px -96px;
}
.ui-icon-suitcase {
  background-position: -112px -96px;
}
.ui-icon-comment {
  background-position: -128px -96px;
}
.ui-icon-person {
  background-position: -144px -96px;
}
.ui-icon-print {
  background-position: -160px -96px;
}
.ui-icon-trash {
  background-position: -176px -96px;
}
.ui-icon-locked {
  background-position: -192px -96px;
}
.ui-icon-unlocked {
  background-position: -208px -96px;
}
.ui-icon-bookmark {
  background-position: -224px -96px;
}
.ui-icon-tag {
  background-position: -240px -96px;
}
.ui-icon-home {
  background-position: 0 -112px;
}
.ui-icon-flag {
  background-position: -16px -112px;
}
.ui-icon-calendar {
  background-position: -32px -112px;
}
.ui-icon-cart {
  background-position: -48px -112px;
}
.ui-icon-pencil {
  background-position: -64px -112px;
}
.ui-icon-clock {
  background-position: -80px -112px;
}
.ui-icon-disk {
  background-position: -96px -112px;
}
.ui-icon-calculator {
  background-position: -112px -112px;
}
.ui-icon-zoomin {
  background-position: -128px -112px;
}
.ui-icon-zoomout {
  background-position: -144px -112px;
}
.ui-icon-search {
  background-position: -160px -112px;
}
.ui-icon-wrench {
  background-position: -176px -112px;
}
.ui-icon-gear {
  background-position: -192px -112px;
}
.ui-icon-heart {
  background-position: -208px -112px;
}
.ui-icon-star {
  background-position: -224px -112px;
}
.ui-icon-link {
  background-position: -240px -112px;
}
.ui-icon-cancel {
  background-position: 0 -128px;
}
.ui-icon-plus {
  background-position: -16px -128px;
}
.ui-icon-plusthick {
  background-position: -32px -128px;
}
.ui-icon-minus {
  background-position: -48px -128px;
}
.ui-icon-minusthick {
  background-position: -64px -128px;
}
.ui-icon-close {
  background-position: -80px -128px;
}
.ui-icon-closethick {
  background-position: -96px -128px;
}
.ui-icon-key {
  background-position: -112px -128px;
}
.ui-icon-lightbulb {
  background-position: -128px -128px;
}
.ui-icon-scissors {
  background-position: -144px -128px;
}
.ui-icon-clipboard {
  background-position: -160px -128px;
}
.ui-icon-copy {
  background-position: -176px -128px;
}
.ui-icon-contact {
  background-position: -192px -128px;
}
.ui-icon-image {
  background-position: -208px -128px;
}
.ui-icon-video {
  background-position: -224px -128px;
}
.ui-icon-script {
  background-position: -240px -128px;
}
.ui-icon-alert {
  background-position: 0 -144px;
}
.ui-icon-info {
  background-position: -16px -144px;
}
.ui-icon-notice {
  background-position: -32px -144px;
}
.ui-icon-help {
  background-position: -48px -144px;
}
.ui-icon-check {
  background-position: -64px -144px;
}
.ui-icon-bullet {
  background-position: -80px -144px;
}
.ui-icon-radio-on {
  background-position: -96px -144px;
}
.ui-icon-radio-off {
  background-position: -112px -144px;
}
.ui-icon-pin-w {
  background-position: -128px -144px;
}
.ui-icon-pin-s {
  background-position: -144px -144px;
}
.ui-icon-play {
  background-position: 0 -160px;
}
.ui-icon-pause {
  background-position: -16px -160px;
}
.ui-icon-seek-next {
  background-position: -32px -160px;
}
.ui-icon-seek-prev {
  background-position: -48px -160px;
}
.ui-icon-seek-end {
  background-position: -64px -160px;
}
.ui-icon-seek-start {
  background-position: -80px -160px;
}
/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
  background-position: -80px -160px;
}
.ui-icon-stop {
  background-position: -96px -160px;
}
.ui-icon-eject {
  background-position: -112px -160px;
}
.ui-icon-volume-off {
  background-position: -128px -160px;
}
.ui-icon-volume-on {
  background-position: -144px -160px;
}
.ui-icon-power {
  background-position: 0 -176px;
}
.ui-icon-signal-diag {
  background-position: -16px -176px;
}
.ui-icon-signal {
  background-position: -32px -176px;
}
.ui-icon-battery-0 {
  background-position: -48px -176px;
}
.ui-icon-battery-1 {
  background-position: -64px -176px;
}
.ui-icon-battery-2 {
  background-position: -80px -176px;
}
.ui-icon-battery-3 {
  background-position: -96px -176px;
}
.ui-icon-circle-plus {
  background-position: 0 -192px;
}
.ui-icon-circle-minus {
  background-position: -16px -192px;
}
.ui-icon-circle-close {
  background-position: -32px -192px;
}
.ui-icon-circle-triangle-e {
  background-position: -48px -192px;
}
.ui-icon-circle-triangle-s {
  background-position: -64px -192px;
}
.ui-icon-circle-triangle-w {
  background-position: -80px -192px;
}
.ui-icon-circle-triangle-n {
  background-position: -96px -192px;
}
.ui-icon-circle-arrow-e {
  background-position: -112px -192px;
}
.ui-icon-circle-arrow-s {
  background-position: -128px -192px;
}
.ui-icon-circle-arrow-w {
  background-position: -144px -192px;
}
.ui-icon-circle-arrow-n {
  background-position: -160px -192px;
}
.ui-icon-circle-zoomin {
  background-position: -176px -192px;
}
.ui-icon-circle-zoomout {
  background-position: -192px -192px;
}
.ui-icon-circle-check {
  background-position: -208px -192px;
}
.ui-icon-circlesmall-plus {
  background-position: 0 -208px;
}
.ui-icon-circlesmall-minus {
  background-position: -16px -208px;
}
.ui-icon-circlesmall-close {
  background-position: -32px -208px;
}
.ui-icon-squaresmall-plus {
  background-position: -48px -208px;
}
.ui-icon-squaresmall-minus {
  background-position: -64px -208px;
}
.ui-icon-squaresmall-close {
  background-position: -80px -208px;
}
.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px;
}
.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px;
}
.ui-icon-grip-solid-vertical {
  background-position: -32px -224px;
}
.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px;
}
.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}
.ui-icon-grip-diagonal-se {
  background-position: -80px -224px;
}
/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  background: #aaaaaa;
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* support: IE8 */
}
.ui-widget-shadow {
  -webkit-box-shadow: 0px 0px 5px #666666;
  box-shadow: 0px 0px 5px #666666;
}
.ui-datepicker-prev span,
.ui-datepicker-next span {
  background-image: none !important;
}
.ui-datepicker-prev:before,
.ui-datepicker-next:before {
  font-family: 'Material Icons';
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
}
.ui-datepicker-prev:hover,
.ui-datepicker-next:hover {
  cursor: pointer;
  color: #1E71CD;
  background-color: #FFFFFF;
  border: 1px solid #268AF9;
}
.ui-datepicker-prev:active,
.ui-datepicker-next:active {
  color: #063D7A;
}
.ui-datepicker-prev:focus,
.ui-datepicker-next:focus {
  background-color: transparent;
  outline: none;
}
.ui-datepicker-prev:before {
  content: "\e5cb";
}
.ui-datepicker-next:before {
  content: "\e5cc";
}
.hca-datepicker-icon {
  color: #7F94A2;
  display: block;
  border-left: 1px solid #D8DDE6 !important;
}
.hca-datepicker-icon:hover {
  cursor: pointer;
}
.hca-text-field--has-icon span {
  display: block;
  color: #7F94A2;
}
#buttonRow.ui-state-hover {
  border-radius: 5px;
  background-color: none;
}
.ui-datepicker-inline {
  margin: 0px auto;
  box-shadow: none !important;
  padding: 0px !important;
}
